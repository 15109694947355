<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `clients.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:item.valid="{ item }">
      <v-simple-checkbox
        v-model="item.valid"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <v-row>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="slotProps.item.name"
                autofocus
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <autocomplete-select
                apiPath="admin/groups"
                textField="name"
                :multiple="false"
                :readonly="slotProps.item.id != null"
                :filters="{client: createData.client}"
                v-model="slotProps.item.group"
                label="Group"
                :error-messages="itemErrors.fieldErrors.group || []"
              ></autocomplete-select>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import DataTable from './DataTable'
import AutocompleteSelect from '@/components/AutocompleteSelect'

export default {
  mixins: [DataTable],
  components: {
    AutocompleteSelect,
  }
}
</script>