var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results.results,"options":_vm.options,"server-items-length":_vm.results.count,"loading":_vm.isLoading,"footer-props":{itemsPerPageOptions: [5, 25, 50, 100]}},on:{"update:options":[function($event){_vm.options=$event},function($event){_vm.options = _vm.options}]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: (_vm.routerNamespace + ".detail"), params: {id: item.id}}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: "clients.detail", params: {id: item.client.id}}}},[_vm._v(" "+_vm._s(item.client.name)+" ")])]}},{key:"item.valid",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.valid),callback:function ($$v) {_vm.$set(item, "valid", $$v)},expression:"item.valid"}})]}},{key:"top",fn:function(){return [_c('data-table-top',{ref:"top",attrs:{"form-title":_vm.formTitle,"item":_vm.editedItem,"entity-name":_vm.entityName,"entity-name-plural":_vm.entityNamePlural,"can-create":_vm.canCreate,"can-edit":_vm.canEdit,"query":_vm.filters.q},on:{"update:query":function($event){return _vm.$set(_vm.filters, "q", $event)},"save":function($event){return _vm.save($event)},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"modalForm",fn:function(slotProps){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"autofocus":"","label":"Name"},model:{value:(slotProps.item.name),callback:function ($$v) {_vm.$set(slotProps.item, "name", $$v)},expression:"slotProps.item.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('autocomplete-select',{attrs:{"apiPath":"admin/groups","textField":"name","multiple":false,"readonly":slotProps.item.id != null,"filters":{client: _vm.createData.client},"label":"Group","error-messages":_vm.itemErrors.fieldErrors.group || []},model:{value:(slotProps.item.group),callback:function ($$v) {_vm.$set(slotProps.item, "group", $$v)},expression:"slotProps.item.group"}})],1)],1)]}}],null,false,1645975663)})]},proxy:true},(_vm.canEdit(_vm.item) || _vm.canDestroy(_vm.item))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPencil)+" ")]):_vm._e()]}}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }